
import formatTimeHoursMinutes from "@/app/infrastructures/misc/common-library/FormatTimeHoursMinutes";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { formatTimeNumber } from "@/app/infrastructures/misc/Utils";
import { ConfigFakeDexIndicationController } from "@/app/ui/controllers/ConfigFakeDexIndicationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { EditConfigFakeDexIndicationRequest } from "@/data/payload/api/ConfigFakeDexIndicationRequest";
import { ApiStateData } from "@/domain/entities/Api";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Options, Vue } from "vue-class-component";

@Options({
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  },
  components: {
    DetailLayout
  }
})
export default class EditConfigFakeIndicationDex extends Vue {
  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  get sourceDex() {
    return this.detailData.data.dfdcType === "source_dex";
  }

  apiDetailData = new ApiStateData();
  detailData = new ResponsePayloadV2();
  isActive = false;

  async fetchDetail() {
    try {
      this.apiDetailData.loading = true;
      this.detailData = await ConfigFakeDexIndicationController.getDetail(
        this.id
      );
      this.detailData.data.dfdcOfficeStartTime = this.formatTime(
        this.detailData.data.dfdcOfficeStartTime
      );
      this.detailData.data.dfdcOfficeEndTime = this.formatTime(
        this.detailData.data.dfdcOfficeEndTime
      );
      this.detailData.data.dfdcParamStartTime = this.formatTime(
        this.detailData.data.dfdcParamStartTime
      );
      this.detailData.data.dfdcParamEndTime = this.formatTime(
        this.detailData.data.dfdcParamEndTime
      );
      this.detailData.data.dfdcReasonCategory = this.detailData.data.dfdcReasonCategory.toLowerCase();
      this.isActive =
        this.detailData.data.dfdcStatus?.toLowerCase() === "active"
          ? true
          : false;
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      this.apiDetailData.loading = false;
    }
  }

  formatTime(time: string) {
    const timeData = time.split(":");
    return new Date(
      new Date().setHours(+timeData[0], +timeData[1], 0, 0)
    ).toString();
  }

  formatTimeNumber(value: string) {
    return formatTimeNumber(value)
      .toString()
      .slice(0, -3);
  }

  goBack() {
    this.$router.back();
  }

  get isFormValid() {
    const closedAddress =
      this.detailData.data.dfdcOfficeStartTime &&
      this.detailData.data.dfdcOfficeEndTime &&
      this.detailData.data.dfdcParamStartTime &&
      this.detailData.data.dfdcParamEndTime;
    const outsideOpsHour =
      this.detailData.data.dfdcParamStartTime &&
      this.detailData.data.dfdcParamEndTime;
    return (
      this.detailData.data.dfdcDescription &&
      ((this.detailData.data.dfdcType === "reason_closed_address"
        ? closedAddress
        : true) ||
        (this.detailData.data.dfdcType === "reason_courier_outside_ops_hour"
          ? outsideOpsHour
          : true))
    );
  }

  onUpdateCheckBox(value: boolean, type: string) {
    switch (type) {
      case "office":
        this.detailData.data.dfdcOfficeExcludeWeekendHoliday = value;
        break;
      case "house":
        this.detailData.data.dfdcParamExcludeWeekendHoliday = value;
        break;
      case "genesis":
        this.detailData.data.dfdcGenesis = value;
        break;
      case "driver":
        this.detailData.data.dfdcDriverApp = value;
        break;
      default:
        break;
    }
  }

  async onSubmit() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const payload = new EditConfigFakeDexIndicationRequest({
      dfdcName: this.detailData.data.dfdcName,
      dfdcDescription: this.detailData.data.dfdcDescription,
      dfdcType: this.detailData.data.dfdcType,
      dfdcStatus: this.isActive ? "active" : "inactive",
      dfdcReasonCode: this.detailData.data.dfdcReasonCode,
      dfdcReasonCategory: this.detailData.data.dfdcReasonCategory,
      dfdcParamStartTime: this.formatTimeNumber(
        this.detailData.data.dfdcParamStartTime
      ),
      dfdcParamEndTime: this.formatTimeNumber(
        this.detailData.data.dfdcParamEndTime
      ),
      dfdcParamExcludeWeekendHoliday: this.detailData.data
        .dfdcParamExcludeWeekendHoliday,
      dfdcOfficeStartTime: this.formatTimeNumber(
        this.detailData.data.dfdcOfficeStartTime
      ),
      dfdcOfficeEndTime: this.formatTimeNumber(
        this.detailData.data.dfdcOfficeEndTime
      ),
      dfdcOfficeExcludeWeekendHoliday: this.detailData.data
        .dfdcOfficeExcludeWeekendHoliday,
      dfdcGenesis: this.detailData.data.dfdcGenesis,
      dfdcDriverApp: this.detailData.data.dfdcDriverApp
    });
    try {
      await ConfigFakeDexIndicationController.editConfig({
        payload,
        id: this.id
      });
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Perubahan Konfigurasi Automasi Indikasi DEX Palsu Berhasil !",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success"
        })
      );
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          err,
          "Perubahan Konfigurasi Automasi Indikasi DEX Palsu Gagal !",
          () => this.onSubmit()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  // route logic
  leavePageConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;

  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }

  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }
}
