import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { ConfigFakeDexIndicationRequest } from "../contracts/ConfigFakeDexIndicationRequest";

export class EditConfigFakeDexIndicationRequest implements ConfigFakeDexIndicationRequest {
  dfdcName = "";
  dfdcDescription = "";
  dfdcType = "";
  dfdcStatus = "";
  dfdcReasonCode = "";
  dfdcReasonCategory = "";
  dfdcParamStartTime = "";
  dfdcParamEndTime = "";
  dfdcParamExcludeWeekendHoliday = false;
  dfdcOfficeStartTime = "";
  dfdcOfficeEndTime = "";
  dfdcOfficeExcludeWeekendHoliday = false;
  dfdcGenesis = false;
  dfdcDriverApp = false;
  constructor(fields?: Partial<EditConfigFakeDexIndicationRequest>) {
    Object.assign(this, fields);
  }
  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this))
  }

}